.background {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../assets/header_background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 560px;
}

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 600px;
}

.navBar {
    width: 100%;
    padding-left: 56px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.mainContent {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 600px;
    padding-top: 40px;
}

.heroImage {
    margin-left: 80px;
    flex: 0 0 300px;
    min-width: 300px;
    max-width: 400px;
}

.logo {
    width: 120px;
}

.textContainer {
    width: 50%;
    flex-shrink: 0;
    flex-wrap: nowrap;
}

.title {
    color: white;
    font-weight: 700;
    line-height: 114%;
}

.caption {
    color: white;
    font-weight: 400;
    font-family: 'Albert Sans';
    font-size: 1.2em;
}

.formContainer {
    display: flex;
}

.input {
    flex: 2 1 0;
    margin-right: 25px;
    padding: 14px;
    border-radius: 8px;
    border: 1px solid #C6C8D3;
    background: white;
    font-family: 'Albert Sans';
    max-height: 50px;
}

.input:focus {
    border: 1px solid #FFBC3C;
}

.cta {
    flex: 1 1 0;
    padding: 16px;
    color: white;
    border-radius: 6px;
    background: #FFBC3C;
    cursor: pointer;
    text-overflow: ellipsis;
    max-height: 50px;
    text-wrap: nowrap;
}

.cta:hover {
    transition-duration: 0.4s;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.cta:active {
    box-shadow: 0 0px 0px;
}

.avatarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

h3.avatarCaption {
    color: white;
    margin-left: 24px;
}

.phonePreview {
    margin-top: 200px;
    width: 360px;
}