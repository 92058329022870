body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 'Albert Sans';
}

h1 {
  font-size: 2.6em;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  font-family: 'Unbounded';
}
