.cta {
    flex: 1 1 0;
    padding: 16px;
    color: white;
    border-radius: 6px;
    background: #FFBC3C;
    cursor: pointer;
    text-overflow: ellipsis;
    max-height: 50px;
    text-wrap: nowrap;
    width: 30%;
    text-align: center;
    font-family: 'Albert Sans';
    font-weight: 500;
}

.cta:hover {
    transition-duration: 0.4s;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.cta:active {
    box-shadow: 0 0px 0px;
}

.header {
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    font-family: 'Unbounded';
}