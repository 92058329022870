.container {
    border-top: 1px solid #D8D8D8;
    background: #F8F8F8;
    width: 100%;
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 120px;
    margin-left: 60px;
}

.captionText {
    font-family: 'Albert Sans';
    font-size: 0.8em;
    color: #667085;
    text-align: right;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-right: 60px;
}