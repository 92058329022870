.container {
    width: 80%;
    background-color: #F8F8F8;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    min-width: 600px;
}

.valueContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.valueImage1 {
    width: 60%;
}

.valueImage2 {
    width: 50%;
    margin-left: 50px;
}

.valueText2Container {
    margin-left: 50px;
}

.valueTitle {
    font-family: 'Unbounded';
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 20px;
}

.valueCaption {
    color: #383A47;
    font-family: "Albert Sans";
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}