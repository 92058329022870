.root {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    background-color: #F8F8F8;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    min-width: 600px;
}

.itemRoot {
    display: flex;
    row-gap: 60px;
    margin: 20px;
}

.index {
    font-family: 'Unbounded';
    font-size: 1.6em;
    font-style: normal;
    font-weight: 700;
    line-height: 70%;
    color: #1B1C20;
    /* background-color:#fff; */
    /* border: 2px solid #000;     */
    padding: 20px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    align-items: center;
    align-content: center;
    margin-right: 20px;
}

.textContainer {
    display: flex;
    flex-direction: column;
}

.title {
    font-family: 'Unbounded';
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    color: #1B1C20;
}

.caption {
    font-family: 'Albert Sans';
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #383A47;
}